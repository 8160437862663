import { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Header } from 'semantic-ui-react';

// components
import Layout from '../components/layout';

// props
import { UserInfoProps } from '../types';
import HelpModal from '../components/modals/HelpModal';
import { DashboardHelp } from '../utils/helpContent';

const Home = () => {
  const history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState<UserInfoProps | null>(null);
  const [openHelp,setOpenHelp] = useState<Boolean>()

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info: any) => {
        setUserInfo(info);
      }).catch((err) => {
        console.error(err);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const login = async () => {
    history.push('/login');
  };

  const resourceServerExamples = [
    {
      label: 'Node/Express Resource Server Example',
      url: 'https://github.com/okta/samples-nodejs-express-4/tree/master/resource-server',
    },
    {
      label: 'Java/Spring MVC Resource Server Example',
      url: 'https://github.com/okta/samples-java-spring/tree/master/resource-server',
    },
    {
      label: 'ASP.NET Core Resource Server Example',
      url: 'https://github.com/okta/samples-aspnetcore/tree/master/samples-aspnetcore-3x/resource-server',
    },
  ];

  if (!authState) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <Layout title="Dashboard">
      <HelpModal
        open={openHelp}
        setOpen={setOpenHelp}
        title = {DashboardHelp.title}
        content = {DashboardHelp.content} />
      <div className="sec-info control-head">
        <div className="head">
          <h1 className="head-lg">SMPD-MVP Digital Hub</h1>
        </div>
        <div className='right'>
          <Link to="#" title='Help' className="icon-btn alter" onClick={() => { setOpenHelp(true) }}>
            <i className="fa-solid fa-question" />
          </Link>
        </div>
      </div>

      <div className="theme-card  admin-h">
        <div className="body">
          <div className="md-container-2">
            <div className="row  btn-list">
              <div className="col-md-6">
                <Link
                  to="/materials"
                  className='theme-btn brick w-full fs-28'
                  title="Material Library"
                  data-cy="material-library"
                >
                  Material Library
                </Link>
              </div>
              <div className="col-md-6">
                <Link
                  to="/equipments"
                  className='theme-btn brick w-full fs-28'
                  title="Equipment Library"
                >
                  Equipment Library
                </Link>
              </div>
              <div className="col-md-6 mx-auto">
                <Link
                  to="/internal-processes"
                  className="theme-btn brick w-full fs-28"
                  title="Apps & Models"
                >
                  Apps & Models
                </Link>
              </div>
              {/*<div className="col-md-5">*/}
              {/*  <Link*/}
              {/*    to="/equipments"*/}
              {/*    className='theme-btn brick w-full fs-28'*/}
              {/*    title='External Cos'*/}
              {/*  >*/}
              {/*    External Cos*/}
              {/*  </Link>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
      {/* useless code */}
      <div className='hide'>
        <Header as="h1">PKCE Flow w/ Custom Login Page</Header>

        {authState.isAuthenticated && !userInfo
          && <div>Loading user information...</div>}

        {authState.isAuthenticated && userInfo
          && (
            <div>
              <p id="welcome">
                Welcome, &nbsp;
                {userInfo.name}
                !
              </p>
              <p>
                You have successfully authenticated against your Okta org, and have been redirected back to this application.  You now have an ID token and access token in local storage.
                Visit the
                {' '}
                <a href="/profile">My Profile</a>
                {' '}
                page to take a look inside the ID token.
              </p>
              <h3>Next Steps</h3>
              <p>Currently this application is a stand-alone front end application.  At this point you can use the access token to authenticate yourself against resource servers that you control.</p>
              <p>This sample is designed to work with one of our resource server examples.  To see access token authentication in action, please download one of these resource server examples:</p>
              <ul>
                {resourceServerExamples.map((example) => <li key={example.url}><a href={example.url}>{example.label}</a></li>)}
              </ul>
              <p>
                Once you have downloaded and started the example resource server, you can visit the
                {' '}
                <a href="/messages">My Messages</a>
                {' '}
                page to see the authentication process in action.
              </p>
            </div>
          )}

        {!authState.isAuthenticated
          && (
            <div>
              <p>If you&lsquo;re viewing this page then you have successfully started this React application.</p>
              <p>
                <span>This example shows you how to use the </span>
                <a href="https://github.com/okta/okta-react/tree/master">Okta React Library</a>
                <span> to add the </span>
                <a href="https://developer.okta.com/docs/guides/implement-auth-code-pkce">PKCE Flow</a>
                <span> to your application.</span>
              </p>
              <p>
                When you click the login button below, you will be presented the login page on the Okta Sign-In Widget hosted within the application.
                After you authenticate, you will be logged in to this application with an ID token and access token. These tokens will be stored in local storage and can be retrieved at a later time.
              </p>
              <Button title="Login" id="login-button" primary onClick={login}>Login</Button>
            </div>
          )}
      </div>
    </Layout>
  );
};
export default Home;
