import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Sortable from "../../../../components/solubility/Sortable";
import MaterialService from "../../../../services/materialService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import useAlert from "../../../../hooks/useAlert";
import DeleteConfirmModal from "../../../../components/modals/deleteConfirmModal";
import { sortByField } from "../../../../utils/common";
import CustomLoader from "../../../../components/common/CustomLoader";
import CancelConfirmModal from "../../../../components/modals/CancelFormModal";
import { useHistory } from "react-router-dom";
import Select from 'react-select';

function useSolvents() {
  const [loading, setLoading] = useState(false);
  const [solvents, setSolvents] = useState<any>([]);
  useEffect(() => {
    setLoading(true);
    MaterialService.getSolvents().then(res => {
      if (res.status === 200 && res.data.body.solvensts) {
        setSolvents(res.data.body.solvensts || []);
      }
    });

  }, []);
  return solvents;
}
interface CmacModelsProps {
  setSubmitFn: (fn: () => void) => void;
  setCloseFn: (fn: () => void) => void;
  model: string;
  saveCMACModel: (data: any) => Promise<any>;
  refreshCMACData: () => void;
  calculateSolubility: (payload: any) => Promise<any>;
  simulateLoading:boolean;
  setSimulateLoading: (value: boolean) => void

}

export function useCMACModel(queryData: any) {

  const [cmacData, setCMACData] = useState<any>([]);
  const [loadingCMACData, setLoadingCMACData] = useState(false);

  const loadCMACData = useCallback((payload: any) => {
    if (!payload) {
      setCMACData([]);
      setLoadingCMACData(false);
    } else {
      MaterialService.getCMAC(payload).then(res => {
        if (res.data.code === 200) setCMACData(res.data.body);
        else if (res.data.code === 404) setCMACData([]);
        setLoadingCMACData(false);
      });
    }
  }, []);

  const calculateSolubility = useCallback((payload: any) => {
    return MaterialService.calculateCMACSolubility(payload);
  }, []);

  const refreshCMACData = useCallback(() => {
    return loadCMACData(queryData);
  }, [queryData]);

  useEffect(() => {
    setLoadingCMACData(true);
    loadCMACData(queryData);
  }, [queryData, loadCMACData]);

  const saveCMACModel = useCallback(async function (data: any) {
    return MaterialService.saveCMAC(data);
  }, []);

  const deleteCMACRecord = useCallback(async function (data: any) {
    return MaterialService.deleteCMACRecord(data);
  }, []);

  return {
    saveCMACModel,
    cmacData,
    refreshCMACData,
    calculateSolubility,
    deleteCMACRecord,
    loadingCMACData,
  };
}


export function ResultsTable({ results, refresh, deleteCMACRecord }: { results: any, refresh: any, deleteCMACRecord: any }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [toDelete, setToDelete] = useState("");
  const [sortBy, setSortBy] = useState<string>("y");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const manageSort = (newSortBy: string) => {
    let newSortOrder: "asc" | "desc" = "desc";
    if (newSortBy == sortBy) {
      newSortOrder = sortOrder == "asc" ? "desc" : "asc";
    }
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    // model3Simulations = sortByField(model3Simulations, newSortBy, newSortOrder);
    // setModel3Simulations(model3Simulations);
  };
  const sortedResults = useMemo(() => sortByField(results, sortBy, sortOrder), [results, sortBy, sortOrder]);
  return <div className="table-outer model-3-table">
    <DeleteConfirmModal
      open={showDeleteModal}
      confirmMessage="Are you sure, do you want to delete this experiment?"
      setOpen={setShowDeleteModal}
      deleteHandle={() => {
        if (toDelete)
          deleteCMACRecord({ id: toDelete }).then(() => refresh());
        setShowDeleteModal(false);
      }}

    />
    <div className="text-right sortable-link">
      <button onClick={() => {
        refresh();
      }} className="pr-5 pb-5"><i className="fa fa-refresh"></i></button>
    </div>
    <div className="table-responsive theme-table">
      <table className="table">
        <thead>
          <tr>
            <th>
              <Sortable
                title="Solvent"
                fieldName="solvent"
                sortBy={sortBy}
                sortOrder={sortOrder}
                manageSort={manageSort}
              />
            </th>
            <th>
              <Sortable
                title="Temperature (C)"
                fieldName="temperature"
                sortBy=""
                sortOrder=""
                manageSort={() => {
                }}
              />
            </th>
            <th>
              <Sortable
                title="Prediction Solubility (mg/ml solvent at 25°C)"
                fieldName="solubility"
                sortBy={sortBy}
                sortOrder={sortOrder}
                manageSort={manageSort}
              />
            </th>
            <th className="w-10">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {
            sortedResults.map((row: any, index: any) => <tr key={index}>
              <td className="text-center">{row.solvent}</td>
              <td className="text-center">25</td>
              <td className="text-center">{row.solubility_prediction}</td>
              <td className="w-10">
                <button type="button" className="icon-btn sm plain" title="Delete" onClick={() => {
                  setShowDeleteModal(true);
                  setToDelete(row.id);
                }}>
                  <i className="fa-solid fa-trash" />
                </button>
              </td>
            </tr>)
          }
        </tbody>
      </table>
    </div>
  </div>;
}

function useMaterial() {
  const { item: material } = useSelector((state: RootState) => state.material);
  return {
    material: Array.isArray(material) ? null : material,
  };
}

function ModelForm({
  model,
  solventIndex,
  setSolventIndex,
  solvents,
  setSolubility,
  onSimulate,
  solubility,
  simulateLoading,
}: {
  model: any,
  solventIndex: any,
  setSolventIndex: any,
  solvents: any,
  setSolubility: any,
  onSimulate: any,
  solubility: any,
  simulateLoading: any
}) {
  const solventOptions = solvents.map((solvent: any, index: number) => ({
    value: index,
    label: solvent.material_name,
  }));
  return <div>
    <div className="form-group">
      <label className="ip-wrap mb-2" htmlFor="solvent">
        <div className="input-wrap">
          <span className="form-label">Solvent (Density - g/ml)</span>
          <Select
            styles={{
              control: (base: any, state) => ({
                ...base,
                fontFamily: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif",
    fontSize: '14px',
                '&:hover': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
              }),
            }}
            value={solventOptions.find((option: any) => option.value === solventIndex)}
            onChange={(arr: any) => {
              setSolventIndex(arr?.value || -1)
            }}
            placeholder={"Type Solvent Name..."}
            // isSearchable={false}
            options={solventOptions}
            className="basic-single"
            classNamePrefix="select"
          />
        </div>
      </label>
    </div>
    <div className="form-group">
      <label className="ip-wrap">
        <span className="form-label">Prediction Solubility (mg/ml solvent at 25°C)</span>
        <div className="input-wrap">
          <input type="text" disabled className="theme-ip" value={solubility || ""} />
        </div>
      </label>
    </div>
    <div className="form-group custome-fs">
      <button
        onClick={onSimulate}
        type="button"
        title="Run"
        className="theme-btn mb-6 w-full btn-md">
        {
          simulateLoading ?
            <div className="h-6 w-6 m-0 border-4 rounded-full animate-spin" style={{
              borderTopColor: "white",
            }} /> : "Run"
        }
      </button>
    </div>
  </div>;
}


export default function CmacModels({
  setSubmitFn,
  setCloseFn,
  model,
  saveCMACModel,
  refreshCMACData,
  calculateSolubility,
  simulateLoading, 
  setSimulateLoading
}: CmacModelsProps) {
  const { material } = useMaterial();
  const history = useHistory();
  useEffect(() => {
    setSolventIndex(-1);
    setSolubility(null);
  }, [material]);
  const [solventIndex, setSolventIndex] = useState<any>(-1);
  const [solubility, setSolubility] = useState<any>(null);

  const saveBtnRef = useRef<any>(null);
  const cancelBtnRef = useRef<any>(null);
  const solvents = useSolvents();
  const { errorAlert, successAlert } = useAlert();
  const [saveForm, setSaveForm] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(true);

  useEffect(() => {
    function save() {
      saveBtnRef.current?.click();
    }

    function cancel() {
      cancelBtnRef.current?.click();
    }

    setSubmitFn(() => save);
    setCloseFn(() => cancel);
  }, [saveCMACModel, model, solventIndex]);
  useEffect(() => {
    setSolubility(null);
  }, [material, model, solventIndex]);
  return <div>
    {saveForm && <CustomLoader />}
    <div className="col-md-12">
      <ModelForm model={model} solventIndex={solventIndex} setSolventIndex={setSolventIndex}
        solvents={solvents} setSolubility={setSolubility} solubility={solubility}
        simulateLoading={simulateLoading}
        onSimulate={() => {
          if (simulateLoading) return;
          if (!material) {
            errorAlert("Please select a material");
            return;
          }
          if (!material.smiles || material.smiles === "NA") {
            errorAlert("This material doesn't have SMILES associated.");
            return;
          }
          if (model === "") {
            errorAlert("Please select a model");
            return;
          }
          if (model !== "CMAC") return;
          if (solventIndex === -1) {
            errorAlert("Please select a solvent");
            return;
          }
          const solvent = solvents[solventIndex];
          if (!solvent.smiles || solvent.smiles === "NA") {
            errorAlert("This solvent doesn't have SMILES associated.");
            return;
          }
          if (!solvent.density || solvent.density === "NA") {
            errorAlert("This solvent doesn't have density associated.");
            return;
          }
          setSimulateLoading(true);
          calculateSolubility({
            solute_smiles: material.smiles,
            solvent_smiles: solvent.smiles,
            solvent_density: solvent.density,
          }).then((res: any) => {
            const { solubility_prediction } = res.data;
            setSimulateLoading(false);
            setIsSubmitted(false);
            setSolubility(solubility_prediction);
          }).catch(() => {
            errorAlert("SMILES for Solute or Solvent may be invalid");
            setSimulateLoading(false);
          });
        }} />
      <button hidden ref={saveBtnRef} type="button" onClick={() => {
        if (!solubility) {
          errorAlert("Please run first");
          return;
        }
        setSaveForm(true);
        const solvent = solvents[solventIndex];
        const payload = ({
          "uid": material.uid,
          "vendor_name": material.vendor_name,
          "material_name": material.material_name,
          "lot_number": material.lot_number,
          "material_id": material.id,
          "solute_smiles": material.smiles,
          "solvent_smiles": solvent.smiles,
          "solubility_prediction": solubility,
          "solvent": solvent.material_name,
          "solvent_density": solvent.density
        });
        let cb = () => {
        };
        if (showCancelModal) {

          setShowCancelModal(false);
          cb = () => {
            history.push("/materials");
          };
        }
        saveCMACModel(payload).then(() => {
          refreshCMACData();
          setSaveForm(false);
          setIsSubmitted(true);
          cb();
        }).catch((err: any) => {
          errorAlert("Oops! Something went wrong");
          setSaveForm(false);
        });
      }}>Save
      </button>
      <button hidden ref={cancelBtnRef} type="button" onClick={() => {
        if (solubility && !isSubmitted)
          setShowCancelModal(true);
        else {
          history.push("/materials");
        }
      }}>Cancel
      </button>
    </div>
    <CancelConfirmModal
      open={showCancelModal}
      setOpen={setShowCancelModal}
      saveMaterial={() => {
        saveBtnRef.current?.click();
      }}
      dontSave={() => {
        setShowCancelModal(false);
        history.push("/materials");
      }}
    />
  </div>;
}